import React, { useState, useContext, useEffect, useCallback } from "react";
import Select from "react-select";
import "./DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "./FilterBar.css";
import { FilterContext } from "./FilterProvider";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { constants } from "../../constants/Constants";
import MobileFilter from "../FilterBar/MobileFilter";
import { CustomDatePicker } from "./CustomCheckbox";
import useCustomMediaQuery from "../../utils/useCustomMediaQuery";
import { Grid, Typography } from "@mui/material";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import { ThemeContext } from "../../context/ThemeContext";

const FilterBar = ({ hideDropdowns, hideR360PP = false }) => {
  const {
    regionValues,
    productValues,
    modelValues,
    marketValues,
    sectorValues,
    selectedProgram,
    setSelectedProgram,
    selectedProduct,
    setSelectedProduct,
    selectedModel,
    setSelectedModel,
    selectedSector,
    setSelectedSector,
    selectedMarket,
    setSelectedMarket,
    selectedCustomer,
    setSelectedCustomer,
    selectedStartDate,
    setSelectedStartDate,
    setStartUTCDate,
    setSelectedEndDate,
    setEndUTCDate,
    allApplicationNames,
    setSelectedApplicaitonNames,
    ytdCheckbox,
    setYTDCheckbox,
    selectedRetailer,
    setSelectedRetailer,
    setSelectedPPRegion,
    setSelectedZone,
    setSelectedDistrict,
    selectedFilters,
    setSelectedFilters,
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    projectAllOptions,
    selectedStore,
    setSelectedStore,
    storeOptions,
    setStoreOptions,
    selectedPlant,
    setSelectedPlant,
    plantValues,
  } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const [localContext, setLocalContext] = useState({
    regionValues,
    productValues,
    modelValues,
    marketValues,
    sectorValues,
    selectedProgram,
    setSelectedProgram,
    selectedProduct,
    setSelectedProduct,
    selectedModel,
    setSelectedModel,
    selectedSector,
    setSelectedSector,
    selectedMarket,
    setSelectedMarket,
    selectedCustomer,
    setSelectedCustomer,
    selectedStartDate,
    setSelectedStartDate,
    setStartUTCDate,
    setSelectedEndDate,
    setEndUTCDate,
    allApplicationNames,
    setSelectedApplicaitonNames,
    ytdCheckbox,
    setYTDCheckbox,
    selectedRetailer,
    setSelectedRetailer,
    setSelectedPPRegion,
    setSelectedZone,
    setSelectedDistrict,
    selectedFilters,
    setSelectedFilters,
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    projectAllOptions,
    selectedStore,
    setSelectedStore,
    storeOptions,
    setStoreOptions,
    selectedPlant,
    setSelectedPlant,
    plantValues,
  });

  useEffect(() => {
    setLocalContext({
      regionValues,
      productValues,
      modelValues,
      marketValues,
      sectorValues,
      selectedProgram,
      setSelectedProgram,
      selectedProduct,
      setSelectedProduct,
      selectedModel,
      setSelectedModel,
      selectedSector,
      setSelectedSector,
      selectedMarket,
      setSelectedMarket,
      selectedCustomer,
      setSelectedCustomer,
      selectedStartDate,
      setSelectedStartDate,
      setStartUTCDate,
      setSelectedEndDate,
      setEndUTCDate,
      allApplicationNames,
      setSelectedApplicaitonNames,
      ytdCheckbox,
      setYTDCheckbox,
      selectedRetailer,
      setSelectedRetailer,
      setSelectedPPRegion,
      setSelectedZone,
      setSelectedDistrict,
      selectedFilters,
      setSelectedFilters,
      selectedTool,
      setSelectedTool,
      selectedProject,
      setSelectedProject,
      toolAllOptions,
      projectAllOptions,
      selectedStore,
      setSelectedStore,
      storeOptions,
      setStoreOptions,
      selectedPlant,
      setSelectedPlant,
      plantValues,
    });
  }, [
    regionValues,
    productValues,
    modelValues,
    marketValues,
    sectorValues,
    selectedProgram,
    setSelectedProgram,
    selectedProduct,
    setSelectedProduct,
    selectedModel,
    setSelectedModel,
    selectedSector,
    setSelectedSector,
    selectedMarket,
    setSelectedMarket,
    selectedCustomer,
    setSelectedCustomer,
    selectedStartDate,
    setSelectedStartDate,
    setStartUTCDate,
    setSelectedEndDate,
    setEndUTCDate,
    allApplicationNames,
    setSelectedApplicaitonNames,
    ytdCheckbox,
    setYTDCheckbox,
    selectedRetailer,
    setSelectedRetailer,
    setSelectedPPRegion,
    setSelectedZone,
    selectedFilters,
    setSelectedFilters,
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    projectAllOptions,
    selectedStore,
    setSelectedStore,
    storeOptions,
    setStoreOptions,
    selectedPlant,
    setSelectedPlant,
    plantValues,
  ]);

  const {
    getStartOfMonth,
    getEndOfMonth,
    getCurrentDayOfMonth,
    selectCustomStyles,
    handleProductPageRequestData,
  } = useLayoutNavigation();
  const [programOptions, setProgramOptions] = useState(regionValues);
  const [productOptions, setProductOptions] = useState([]);
  const [displayModel, setDisplayModel] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState(sectorValues);
  const [marketOptions, setMarketOptions] = useState(marketValues);
  const [plantOptions, setPlantOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const isMobile = useCustomMediaQuery("(max-width: 600px)");
  const isTablet = useCustomMediaQuery(
    "(min-width: 601px) and (max-width: 1024px)"
  );

  const commonCodeMonth = (
    <>
      <span className={`selectLabel ${mode}`}>{"Month"}</span>
      <CustomDatePicker
        id="label-month-picker"
        portalId="month-picker"
        setSelectedStartDate={setSelectedStartDate}
        setStartUTCDate={setStartUTCDate}
        setSelectedEndDate={setSelectedEndDate}
        setEndUTCDate={setEndUTCDate}
        getStartOfMonth={getStartOfMonth}
        getEndOfMonth={getEndOfMonth}
        getCurrentDayOfMonth={getCurrentDayOfMonth}
        product={selectedProduct}
        pickerType="month"
        selectedYear={selectedYear}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        checked={ytdCheckbox}
      />
    </>
  );

  const commonCodeYear = (
    <>
      <span className={`selectLabel ${mode}`}>{"Year"}</span>
      <CustomDatePicker
        id="label-year-picker"
        portalId={
          selectedProgram?.programId === constants?.programs?.Automation ||
          selectedProduct?.productId === constants?.products?.Canvas
            ? "automation-year-picker"
            : "year-picker"
        }
        setSelectedStartDate={setSelectedStartDate}
        setStartUTCDate={setStartUTCDate}
        setSelectedEndDate={setSelectedEndDate}
        setEndUTCDate={setEndUTCDate}
        getStartOfMonth={getStartOfMonth}
        getEndOfMonth={getEndOfMonth}
        getCurrentDayOfMonth={getCurrentDayOfMonth}
        product={selectedProduct}
        pickerType="year"
        minSelectableYear={new Date().getFullYear() - 1}
        maxSelectableYear={new Date().getFullYear()}
        setSelectedYear={setSelectedYear}
        selectedYear={selectedYear}
        setSelectedDate={setSelectedDate}
        checked={ytdCheckbox}
      />
    </>
  );

  const ytdFilter = () => {
    if (
      selectedProgram?.programId === constants?.programs?.Automation ||
      selectedProduct?.productId === constants?.products?.Canvas
    ) {
      return (
        <>
          <Grid
            container
            direction="row"
            xs={1}
            justifyContent="right"
            alignItems="center"
          >
            <Grid item className={`ytdText ${mode}`}>
              YTD
            </Grid>
            <Grid item className="switchContainer">
              <CustomSwitch
                checked={ytdCheckbox}
                data-testid="ytd-toggle"
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleCheckboxChange}
              />
            </Grid>
          </Grid>

          <Grid className="paddingTop12">{commonCodeMonth}</Grid>
          <Grid className="paddingTop12">{commonCodeYear}</Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid
            data-testid="checking-this-container"
            container
            item
            direction="row"
            xs={2}
            justifyContent="right"
            alignItems="center"
            spacing={1}
            sx={{ paddingBottom: "10px" }}
          >
            <Grid item>
              <Typography
                className={`${mode}`}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#D0D0D0",
                }}
              >
                YTD
              </Typography>
            </Grid>
            <Grid item>
              <CustomSwitch
                checked={ytdCheckbox}
                data-testid="ytd-toggle"
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleCheckboxChange}
              />
            </Grid>
          </Grid>

          <Grid item data-testid="month-picker">
            {commonCodeMonth}
          </Grid>
          <Grid item>{commonCodeYear}</Grid>
        </>
      );
    }
  };

  useEffect(() => {
    if (Array.isArray(regionValues) && regionValues.length > 0) {
      const filteredRegions = hideR360PP
        ? regionValues.filter(
            (program) => ![2, 3, 4, 5, 6, 7].includes(program?.programId)
          )
        : regionValues;
      if (filteredRegions && filteredRegions.length > 0) {
        setProgramOptions(filteredRegions);
        setSelectedProgram(filteredRegions[0]);
      }
    }
  }, [regionValues, hideR360PP, setSelectedProgram]);

  useEffect(() => {
    if (selectedProgram) {
      const filterCondition = hideR360PP
        ? (product) =>
            product?.id !== constants.products.R360 &&
            product?.id !== constants.products.ShipmentVisibilty
        : () => true;

      const products = productValues.filter(
        (product) =>
          product.programId === selectedProgram.programId &&
          filterCondition(product)
      );

      if (products && products.length > 0) {
        setYTDCheckbox(products[0].productId !== constants.products.R360);
        products[0].productId === constants.products.R360
          ? setMonthDate()
          : setYTDDate();
        setSelectedProduct(products[0]);
        setProductOptions(products);
      } else {
        setSelectedProduct(null);
      }
    }
  }, [selectedProgram, hideR360PP, setSelectedProduct, productValues]);

  useEffect(() => {
    if (selectedProduct && !isMobile && !isTablet) {
      // If selected product is R360, uncheck the YTD checkbox.
      setYTDCheckbox(selectedProduct.productId !== constants.products.R360);
      selectedProduct?.productId === constants.products.R360
        ? setMonthDate()
        : setYTDDate();
      const sectors = sectorValues.filter(
        ({ productId }) =>
          productId === selectedProduct.productId &&
          productId !== constants.products.PromoAI &&
          productId !== constants.products.PricingAI
      );
      const sortedSectors =
        selectedProduct?.productId ===
        constants.products.ManufacturingControlTower
          ? sectors.sort((a, b) => a.sectorName.localeCompare(b.sectorName))
          : sectors;

      setSelectedSector(sortedSectors?.[0] || {});
      setSectorOptions(sortedSectors || []);

      const isGuidedSelling =
        selectedProduct.productId === constants.products.GuidedSelling;
      setDisplayModel(isGuidedSelling);
      setSelectedModel(() => {
        if (isGuidedSelling) {
          const filteredModels = modelValues.filter((model) =>
            hideR360PP
              ? model?.productId === selectedProduct.productId &&
                model?.id !== 2 &&
                model?.id !== 7
              : model?.productId === selectedProduct.productId
          );
          setModelOptions(filteredModels);
          return filteredModels?.[0] || null;
        } else {
          return null;
        }
      });
    }
  }, [
    selectedProduct,
    sectorValues,
    setSelectedSector,
    hideR360PP,
    plantValues,
  ]);

  useEffect(() => {
    if (
      displayModel &&
      selectedModel &&
      Object.keys(selectedModel).length !== 0
    ) {
      const sectors = sectorValues.filter(
        (sector) =>
          sector?.productId === selectedProduct?.productId &&
          sector?.modelId === selectedModel?.id
      );
      setSelectedSector(sectors?.[0] || {});
      setSectorOptions(sectors || []);
    } else if (selectedProduct) {
      const sectors = sectorValues.filter(
        (sector) => sector.productId === selectedProduct.productId
      );
      setSelectedSector(sectors?.[0] || {});
      setSectorOptions(sectors || []);
    }
  }, [selectedModel, displayModel, setSelectedSector, sectorValues]);

  const updateMarketsBasedOnSector = useCallback(
    (selectedSector) => {
      if (selectedSector && !isMobile && !isTablet) {
        const markets = marketValues.filter(
          (market) =>
            market.productId === selectedSector.productId &&
            market.sectorId === selectedSector.sectorId &&
            market.modelId === selectedSector.modelId
        );
        if (markets && markets.length > 0) {
          setSelectedMarket(markets[0]);
          setMarketOptions(markets);
        } else {
          setSelectedMarket({});
          setMarketOptions([]);
        }
      }
    },
    [isMobile, isTablet, marketValues]
  );

  const updatePlantsBasedOnSector = useCallback(
    (selectedSector) => {
      if (selectedSector && !isMobile && !isTablet) {
        const plants = plantValues.filter(
          (plant) => plant.sectorId === selectedSector.sectorId
        );
        if (plants && plants.length > 0) {
          setSelectedPlant(plants[0]);
          setPlantOptions(plants);
        }
      }
    },
    [isMobile, isTablet, plantValues]
  );

  const udpateCustomersBasedOnMarket = useCallback(
    (selectedMarket) => {
      if (selectedMarket && !isMobile && !isTablet) {
        const filteredMarkets = marketValues.filter(
          (market) =>
            market.productId === selectedMarket.productId &&
            market.sectorId === selectedMarket.sectorId
        );
        if (filteredMarkets && filteredMarkets.length > 0) {
          const customers = filteredMarkets[0].customers;
          const transformedCustomers = customers.map((customer) => ({
            id: customer?.customerId,
            label: customer?.customerName,
            value: customer?.customerName,
          }));
          const defaultCustomer = transformedCustomers.find((customer) =>
            customer.label.startsWith("WALMART")
          );
          setSelectedCustomer(
            defaultCustomer ||
              (transformedCustomers.length > 0 ? transformedCustomers[0] : {})
          );
          setCustomerOptions(transformedCustomers);
        } else {
          setSelectedCustomer({});
          setCustomerOptions([]);
        }
      }
    },
    [isMobile, isTablet, marketValues]
  );

  const updateSectorOption = useCallback(
    (option) => {
      setSelectedSector(option);
      updateMarketsBasedOnSector(option);
      updatePlantsBasedOnSector(option);
    },
    [isMobile, isTablet, marketValues]
  );

  useEffect(() => {
    updateMarketsBasedOnSector(selectedSector);
  }, [selectedSector, marketValues, setSelectedMarket]);

  useEffect(() => {
    updatePlantsBasedOnSector(selectedSector);
  }, [selectedSector, plantValues, setSelectedPlant]);

  useEffect(() => {
    if (selectedProduct?.productId === constants?.products?.R360) {
      udpateCustomersBasedOnMarket(selectedMarket);
    }
  }, [selectedMarket, marketValues, setSelectedCustomer]);

  useEffect(() => {
    if (allApplicationNames !== undefined && selectedMarket !== null) {
      const platformNames = allApplicationNames
        .filter((appName) => appName.marketId === selectedMarket.marketId)
        .map((appName) => appName?.platform);
      setSelectedApplicaitonNames(platformNames);
    }
  }, [selectedMarket, allApplicationNames, setSelectedApplicaitonNames]);

  // Code to call the API for logging on product change
  useEffect(() => {
    const productId = selectedProduct?.productId;

    let name = "";
    switch (productId) {
      case constants.products.PepsiConnect:
        name = "PepsiConnect";
        break;
      case constants.products.R360:
        name = "R360";
        break;
      case constants.products.GuidedSelling:
        name = "GuidedSelling";
        break;
      case constants.products.E2EWaste:
        name = "E2EWaste";
        break;
      case constants.products.MLT:
        name = "MLT";
        break;
      case constants.products.ShipmentVisibilty:
        name = "ShipmentVisibility";
        break;
      case constants.products.Automation:
        name = "Automation";
        break;
      case constants.products.PromoAI:
        name = "PromoAI";
        break;
      case constants.products.Canvas:
        name = "Canvas";
        break;
      case constants.products.PricingAI:
        name = "PricingAI";
        break;
      case constants.products.DTC:
        name = "DTC";
        break;
      case constants.products.ManufacturingControlTower:
        name = "ManufacturingControlTower";
        break;
      default:
        name = ""; // Default value if productId is not found
        break;
    }
    const productName = name || "";

    handleProductPageRequestData({
      variables: {
        productId,
        productName,
      },
    });
  }, [selectedProduct?.productId]);

  const setYTDDate = () => {
    const currentDate = new Date();
    const currentYear = selectedYear;
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const firstDayOfYearUTC = new Date(
      firstDayOfYear.getTime() - firstDayOfYear.getTimezoneOffset() * 60000
    );
    setSelectedStartDate(firstDayOfYear);
    setStartUTCDate(firstDayOfYearUTC.toISOString());

    const selectedDate =
      selectedYear === currentDate.getFullYear()
        ? currentDate
        : new Date(currentYear, 12, 0);
    setSelectedDate(selectedDate);

    const monthDate =
      selectedYear === new Date().getFullYear()
        ? getCurrentDayOfMonth()
        : getEndOfMonth(new Date(selectedYear, 12, 0));
    const endOfMonth =
      selectedYear === new Date().getFullYear()
        ? getEndOfMonth(new Date())
        : getEndOfMonth(new Date(selectedYear, 12, 0));
    const selectedEndDate =
      selectedProduct?.productId === constants?.products?.ShipmentVisibilty
        ? monthDate
        : endOfMonth;
    setSelectedEndDate(selectedEndDate);
    setEndUTCDate(selectedEndDate.toISOString());
  };

  const setMonthDate = () => {
    setSelectedStartDate(getStartOfMonth(selectedDate, true));
    setStartUTCDate(getStartOfMonth(selectedDate).toISOString());
    const currentDate =
      selectedYear === new Date().getFullYear()
        ? getCurrentDayOfMonth()
        : getEndOfMonth(new Date(selectedYear, 12, 0));
    const endOfMonth =
      selectedYear === new Date().getFullYear()
        ? getEndOfMonth(new Date())
        : getEndOfMonth(new Date(selectedYear, 12, 0));
    const selectedEndDate =
      selectedProduct?.productId === constants?.products?.ShipmentVisibilty
        ? currentDate
        : endOfMonth;
    setSelectedEndDate(selectedEndDate);
    setEndUTCDate(selectedEndDate.toISOString());
  };

  const handleCheckboxChange = (event) => {
    setYTDCheckbox(event.target.checked);
    if (event?.target?.checked) {
      setYTDDate();
    } else {
      setMonthDate();
    }
  };

  useEffect(() => {
    setYTDDate();
  }, []);

  return (
    <>
      {(isMobile || isTablet) && !hideDropdowns ? (
        <div data-testid="content-phone">
          <MobileFilter
            handleCheckboxChange={handleCheckboxChange}
            isChecked={ytdCheckbox}
            selectedYear={selectedYear}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setSelectedYear={setSelectedYear}
            {...localContext}
          />
        </div>
      ) : (
        <Grid container direction="column" spacing={2}>
          {!hideDropdowns && (
            <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <span className={`selectLabel ${mode}`}>
                  {constants.filterBarPlaceHolder.program}
                </span>
                <Select
                  id="program-select"
                  options={programOptions}
                  isSearchable={false}
                  placeholder={constants.filterBarPlaceHolder.program}
                  styles={{
                    ...selectCustomStyles,
                    control: (provided) => ({
                      ...selectCustomStyles.control(provided),
                      width: "160px",
                    }),
                  }}
                  value={selectedProgram}
                  testId="program-filter"
                  onChange={setSelectedProgram}
                />
              </Grid>
              {selectedProgram?.programId !==
                constants?.programs?.Automation && (
                <Grid item>
                  <span className={`selectLabel ${mode}`}>
                    {constants.filterBarPlaceHolder.product}
                  </span>
                  <Select
                    id="product-select"
                    options={productOptions}
                    isSearchable={false}
                    placeholder={constants.filterBarPlaceHolder.product}
                    styles={{
                      ...selectCustomStyles,
                      control: (provided) => ({
                        ...selectCustomStyles.control(provided),
                        width: "140px",
                      }),
                    }}
                    value={selectedProduct}
                    testId="product-filter"
                    onChange={setSelectedProduct}
                  />
                </Grid>
              )}

              {displayModel && (
                <Grid item>
                  <span className={`selectLabel ${mode}`}>
                    {constants.filterBarPlaceHolder.model}
                  </span>
                  <Select
                    id="model-select"
                    options={modelOptions}
                    isSearchable={false}
                    placeholder={constants.filterBarPlaceHolder.model}
                    styles={selectCustomStyles}
                    value={selectedModel}
                    testId="model-filter"
                    onChange={setSelectedModel}
                  />
                </Grid>
              )}

              {selectedModel?.id !== constants?.modals?.productPairing &&
                selectedModel?.id !== constants?.modals?.shareWise &&
                selectedProgram?.programId !==
                  constants?.programs?.Automation &&
                selectedProduct?.productId !== constants?.products?.Canvas &&
                selectedProgram?.programId !== constants?.programs?.RGM &&
                sectorOptions.length > 0 && (
                  <Grid item>
                    <span className={`selectLabel ${mode}`}>
                      {constants.filterBarPlaceHolder.sector}
                    </span>
                    <Select
                      id="sector-select"
                      options={sectorOptions}
                      isSearchable={false}
                      placeholder={constants.filterBarPlaceHolder.sector}
                      styles={{
                        ...selectCustomStyles,
                        control: (provided) => ({
                          ...selectCustomStyles.control(provided),
                          width: "140px",
                        }),
                      }}
                      value={selectedSector}
                      testId="sector-filter"
                      onChange={updateSectorOption}
                    />
                  </Grid>
                )}
              {selectedModel?.id !== constants?.modals?.productPairing &&
                selectedModel?.id !== constants?.modals?.shareWise &&
                marketOptions.length > 0 &&
                selectedProduct?.productId !== constants?.products?.Canvas &&
                selectedProduct?.productId !==
                  constants?.products?.ManufacturingControlTower && (
                  <Grid item>
                    <span className={`selectLabel ${mode}`}>
                      {constants.filterBarPlaceHolder.market}
                    </span>
                    <Select
                      id="market-select"
                      options={marketOptions}
                      isSearchable={false}
                      placeholder={constants.filterBarPlaceHolder.market}
                      styles={{
                        ...selectCustomStyles,
                        control: (provided) => ({
                          ...selectCustomStyles.control(provided),
                          width: "140px",
                        }),
                      }}
                      value={selectedMarket}
                      testId="market-filter"
                      onChange={setSelectedMarket}
                    />
                  </Grid>
                )}
              {selectedProduct?.productId ===
                constants?.products?.ManufacturingControlTower && (
                <Grid item>
                  <span className={`selectLabel ${mode}`}>
                    {constants.filterBarPlaceHolder.site}
                  </span>
                  <Select
                    id="site-select"
                    options={plantOptions}
                    isSearchable={false}
                    placeholder={constants.filterBarPlaceHolder.site}
                    styles={{
                      ...selectCustomStyles,
                      control: (provided) => ({
                        ...selectCustomStyles.control(provided),
                        width: "100px",
                      }),
                    }}
                    value={selectedPlant}
                    testId="site-filter"
                    onChange={setSelectedPlant}
                  />
                </Grid>
              )}
              {selectedProduct?.productId === constants?.products?.R360 &&
                customerOptions.length > 0 && (
                  <Grid item>
                    <span className={`selectLabel ${mode}`}>
                      {constants.filterBarPlaceHolder.customer}
                    </span>
                    <Select
                      id="customer-select"
                      options={customerOptions}
                      isSearchable={false}
                      placeholder={constants.filterBarPlaceHolder.customer}
                      styles={selectCustomStyles}
                      value={selectedCustomer}
                      testId="customer-filter"
                      onChange={setSelectedCustomer}
                    />
                  </Grid>
                )}

              {(selectedProgram?.programId ===
                constants?.programs?.Automation ||
                selectedProduct?.productId === constants?.products?.Canvas) &&
                ytdFilter()}
            </Grid>
          )}

          {selectedProgram?.programId !== constants?.programs?.Automation &&
            selectedProduct?.productId !== constants?.products?.Canvas && (
              <Grid
                item
                container
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                alignContent="center"
                alignItems="end"
              >
                {ytdFilter()}
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
};
export default FilterBar;
